import { defineStore } from 'pinia';

export const useVideoStore = defineStore('videoStore', () => {
    // Props
    var videoModal = ref({
        type: "",
        src: "/",
        isOpen: false
    });
    
    // Getters
    const getVideoModal = computed(
        () => {
            return videoModal.value;
        }
    );

    //action
    function addVideoUrl(url: string) {
        videoModal.value.src = url;
    }
    function isOpenVideoModal(open: boolean) {
        videoModal.value.isOpen = open;
    }
    function addVideoType(type: string) {
        videoModal.value.type = type;
    }


    return {
        getVideoModal,
        addVideoUrl,
        addVideoType,
        isOpenVideoModal
    }
});